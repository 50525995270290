import React from "react";




import "./CovidPostIvg.css";


function CovidPostIvg() {


 

  return (
    <div> 
        
    <p>
    Téléconsultation<br></br>

    Condition : outils informatique fiable pour les documents nécessaires à la pratique de l’IVG.    
    
    </p>
    <p>
    téléconsultation est possible avec l’utilisation d’un autotest urinaire.    </p>

   

    </div>
  );






  
 
}

export default CovidPostIvg;