import React from "react";
import RecommandationIVG from "../../assets/pdf/RecommandationIVG.pdf";

import Argumentaire from "../../assets/pdf/Argumentaire.pdf";



function Covid() {
    return(
        <div className="Mid">
                        <div className="BorderApp"></div>
            <div className="Core">

                <h1>Page Actualité</h1>
                <p>
                Le décret n° 2022-212 du 19 février 2022 relatif aux conditions de réalisation des interruptions volontaires de grossesse par voie médicamenteuse hors établissements de santé perrinise les dispositions concernant la <bold>téléconsultation, l’abolition du délai de réflexion de 2 jours pour les femmes majeures ainsi que l’allongement du délai à 9SA</bold>.
                </p>
                <p>
                La HAS rappelle que « L’objectif est d’étendre de manière pérenne le délai d’une IVG en ville jusqu’à 9 SA en répondant à plusieurs enjeux : réduire le délai d’accès à l’IVG médicamenteuse, élargir l’offre de soin qui doit être possible en ville comme à l’hôpital mais aussi alléger la charge des établissements de santé qui doivent pouvoir se concentrer sur les IVG chirurgicales »
                    </p> 

                <p>
                Depuis le mercredi 23 février 2022, Le parlement a voté la loi autorisant l’allongement du délai pour la réalisation des IVG chirurgicales en établissement de santé, passant de 14 à 16 SA, ainsi que l’ouverture à la possibilité pour cet acte aux sages-femmes.
                </p>    
                <p>
                Par ailleurs le ministère de la Santé a saisi l’ANSM qui publie en date du 23/02/2022 : 
                <ul>
                    <li>
                        <p>
                        L’Agence nationale de sécurité du médicament et des produits de santé (ANSM) a établi un Cadre de Prescription Compassionnelle (CPC) pour encadrer et sécuriser la prescription du misoprostol en association avec la mifépristone dans l’interruption volontaire de grossesse (IVG) par voie médicamenteuse, au cours de la 8ème et de la 9ème semaine d’aménorrhée, soit lors de la 6ème et de la 7ème semaine de grossesse.
                        </p>
                        <p>
                        Ce CPC a été établi sur la base des nouvelles dispositions introduites par le décret n° 2022-164 du 11 février 2022 relatif aux cadres de prescription compassionnelle et modifiant les dispositions du code de la santé publique relatives aux autorisations d'accès précoce et compassionnel.
                        </p>
                        <p>
                        En outre, l’ANSM a modifié les conditions de prescription et de délivrance des autorisations de mise sur le marché de la mifepristone et du misoprostol afin de permettre la délivrance de ces médicaments directement en pharmacie d’officine après une téléconsultation.
                        </p>
                    </li>
                    <li>
                        <p>
                        Un avis sur l’autotest urinaire pour le contrôle de l’IVG est toujours attendu et en attente de la part de l’ANSM. 
                        </p>
                    </li>
                </ul>
                </p>



                <p>
                Fin de l’état d’urgence sanitaire en date du 1er juin 2021. 
                </p>

                <p>
                Cependant, les recommandations de bonne pratique HAS en date du 12 avril 2021 « actualise ses recommandations concernant l’intervention par méthode médicamenteuse. Outre le fait de pérenniser la possibilité de recourir à une IVG médicamenteuse en ville jusqu’à 9 semaines, ces recommandations précisent le protocole médicamenteux adapté ainsi que les conditions de la prise en charge de cette méthode d’IVG, des consultations médicales jusqu’au suivi post-intervention. »                </p>

                <p>
                De plus, il est précisé que « L'accès à l'IVG doit être simple et rapide : chaque femme doit obtenir un rendez-vous dans les 5 jours suivant son appel. Les consultations médicales préalables à l’IVG peuvent se faire en présentiel ou en téléconsultation, avec l’accord de la femme et si le médecin ou la sage-femme l’estiment possible. »
La séquence des consultations relatives à l’IVG médicamenteuse à domicile ou à l’hôpital reste identique.                 </p>  



               


                  <h2>
                  Concernant le protocole médicamenteux
                      
                      </h2> 


                      <p>

                      Pour les IVG médicamenteuses avant 7SA, le protocole habituel est applicable :
                      <ul>
                          <li>
                          mifépristone, 600 mg par voie orale (Mifégyne® 3 comprimés à 200 mg, en une prise) suivi 36 à 48h après de 400 μg misoprostol, par voie orale (soit Gymiso® 400 μg : 2 comprimés à 200 μg, en une prise ; soit MisoOne® 400 μg : 1 comprimé à 400 μg).
                          </li>
                          <li>
                          Mifépristone, 200mg par voie orale (Mifégyne® 1 comprimé à 200mg, en une prise) suivi 24-48h plus tard de 400 μg misoprostol, par voie transmuqueuse ou sub-linguale (soit Gymiso® 400 μg : 2 comprimés à 200 μg, en une prise ; soit MisoOne® 400 μg : 1 comprimé à 400 μg).
                          </li>

                      </ul>
                      </p>

                      <p>
                      Pour les IVG médicamenteuses entre 7SA et 9SA, la HAS préconise en date du 12 avril 2021 le protocole suivant :

                      <ul>
                          <li>
                          Une prise de 200mg par voie orale (Mifégyne® 1 comprimé à 200mg, en une prise) suivie, 24 à 48 h plus tard, de 800 μg de misoprostol (soit Gymiso® 400 μg : 4 comprimés à 200 μg, en une prise ; soit MisoOne® 400 μg : 2 comprimé à 400 μg) en une seule prise, par voie transmuqueuse orale ou sublinguale (hors AMM). 
                          </li>
                          <li>
                          Il n’est pas recommandé́ d’utiliser la voie vaginale. Voie transmuqueuse orale : les comprimés sont mis en place entre la joue et la gencive et les femmes doivent avaler les fragments résiduels au bout de 30 minutes. 
                          </li>
                      </ul>
                      </p> 


                      <h2>Concernant l’anonymat</h2>  

                      <p>
                      L’anonymat doit désormais être proposé à TOUTES les femmes (mineure, majeure). La prise en charge à 100% par l’AMO ainsi que la pratique du tiers payant obligatoire et l’absence de décompte envoyé à l’assuré garantissent l’anonymat.
                      </p>
                      <p>
                      Il n’est plus nécessaire d’utiliser un NIR anonyme lorsque le NIR réel peut être fiabilisé par un support VITALE.
                      </p>
                      <p>
                      Toutefois, dans le cas où la récupération du NIR réel ne peut être fiabilisé par un support de droit le professionnel de santé doit utiliser :
                     
                      <ul>
                        <li>
                        NIR fictif 2 55 55 55 + code de caisse + 030
                        </li>
                        <li>
                        Date de naissance réelle ou à défaut 01/01/2014
                        </li>

                      </ul>
</p>
                      <p>
                      SI dans tous les cas l’anonymat ne peut être assuré et que la patiente le souhaite, il est nécessaire de la rediriger vers un établissement de santé.
                      </p>

                      <p>
                       « IVG : anonymat et facturation <a target="_blank" rel="noreferrer noopener" href="https://www.ameli.fr/medecin/exercice-liberal/prescription-prise-charge/prise-charge-situation-type-soin/ivg-medicamenteuse#:~:text=Le%20professionnel%20de%20sant%C3%A9%2C%20d%C3%A8s,facture%20seront%20exclus%20des%20d%C3%A9comptes">https://www.ameli.fr/medecin/exercice-liberal/prescription-prise-charge/prise-charge-situation-type-soin/ivg-medicamenteuse#:~:text=Le%20professionnel%20de%20sant%C3%A9%2C%20d%C3%A8s,facture%20seront%20exclus%20des%20d%C3%A9comptes </a>.
                      </p>



                      <h2>
                          
                      Concernant les médicaments (en cas de téléconsultation, non à jour au 12 Avril 2021, pour information)</h2> 




                    
                        <p>
                        Le professionnel de santé, prescrit les traitements abortifs en mentionnant le nom de la pharmacie d’officine désignée par la femme, et transmet la prescription à cette pharmacie selon les modalités de télémédecine dont il dispose. Le nom, les dosages, la posologie, la voie d’administration des médicaments doivent figurer sur l’ordonnance sans ambiguïté.

                        </p>

                        <p>
                        Le professionnel de santé contacte la pharmacie désignée pour s’assurer de la bonne réception de l’ordonnance.
                        </p>


                        <p>
                        Lors du passage de la femme à la pharmacie concernée, le pharmacien d’officine lui délivre les traitements dans un conditionnement adapté à une prise individuelle. Le pharmacien appose sur l'ordonnance le timbre de la pharmacie d’officine, la date de délivrance, les numéros d’enregistrement et la mention « délivrance exceptionnelle ». Il informe ensuite le prescripteur de la délivrance des médicaments à la femme. Cette délivrance s’effectue sans frais pour les femmes concernées, et anonymement.
                            </p>

                            <p>
                            L’ordonnance doit être envoyé par le professionnel de santé prenant en charge l’IVG lors de la 2ème consultation préalable à l’IVG. En pratique, la deuxième consultation préalable est supprimée. 
                                </p>


                                <p>
                                Une téléconsultation supplémentaire est ajoutée afin d’attester de la prise médicamenteuse.
                                 </p>  



                              <p>

                              L’anonymisation de la délivrance par les pharmaciens des médicaments de l’IVG est valable pour toutes les femmes mineures et majeures pour éviter des levées non voulues de confidentialité notamment sur les relevés de remboursement de l’assurance maladie. 
                               </p>


                            <p>Au 12 Avril 2021 : les différents traitements sont remis à la patiente lors des consultations en présentiels. Le misoprostol peut être prescrit afin que la patiente puisse les récupérer directement à la pharmacie. La cotation « forfait médicament » ne doit pas être appliqué dans ce seul cas. </p>          



                        <h2>
                        Concernant les téléconsultations 
                        </h2>



<p>
                        <ul>
                            <li>
                            Seule la première consultation préalable à l’IVG est possible par tous professionnels de santé.
                            </li>
                            <li>
                            La deuxième consultation préalable à l’IVG est remplacée par l’équivalent de la consultation de première prise médicamenteuse et doit par conséquent être effectuée par un professionnel de santé qualifié en orthogénie.
                            </li>
                            <li>
                            La consultation de 1ère prise médicamenteuse se déroule en téléconsultation après être allée chercher les traitements nécessaires en pharmacie, prescrits lors de la téléconsultation précédente. 
                            </li>
                            <li>
                            Le deuxième temps de consultation pour prise de médicamenteuse reste facultative.
                            </li>
                            <li>
                            La consultation post-IVG est possible en téléconsultation. La réussite de la méthode peut être faite par dosage sanguin des BHCG ou par autotest urinaire à domicile.
                            </li>
                            

                            </ul>
                            Au 12 Avril 2021 : Les consultations médicales préalables à l’IVG peuvent se faire en présentiel ou en téléconsultation, avec l’accord de la femme et si le médecin ou la sage-femme l’estiment possible.
                            </p>


            <h2>
            Concernant la tarification 
            </h2>


            <p>
            En termes de codage des téléconsultations ainsi réalisées, sont applicables les cotations prévues en matière d’IVG. Pour tenir compte de la possible délivrance en pharmacie du médicament abortif si la consultation est réalisée à distance, les professionnels sont invités à ôter le forfait médicament de la facturation et à coder ainsi :


            <ul>
                <li>
                Pour les médecins généralistes : IC + FHV + IC
                </li>
                <li>
                Pour les médecins spécialistes : ICS + FHV + ICS
                </li>
                <li>
                Pour les sages-femmes : IC/ICS + FHV + IC/ICS  
                </li>
            </ul>
            La prise en charge sera assurée par l’Assurance maladie obligatoire à 100% AMO. Une tarification dégradée ou une FSP (Feuille de Soins Papier) peuvent être effectués. 
             </p> 



             <p>


             Concernant la femme mineure :
             <br></br>
             Les mêmes dispositions que chez la femme majeure s’appliquent en prenant en compte les mesures spécifique au patiente mineure.
             
             </p>   

             

<br></br>

             

              <p>
              Pour plus d’information :
              <br></br>
              <ul>
              <li>
                      <a href="https://www.ameli.fr/" target="_blank" rel="noreferrer noopener">
                      Ameli.fr
                      </a>, rubrique IVG médicamenteuse. 
                      
                  </li>
                  <li>
                  Réseau périnatalité Eure et Seine Maritime : <a href="https://www.reseaux-perinat-hn.com/" target="_blank" rel="noreferrer noopener">reseaux-perinat-hn.com

                  </a>.
                  </li>
                 <li>
                 <a href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000045190889" target="_blank" rel="noreferrer noopener">
                 Légifrance 22 février 2022 :</a></li> 
               <li>Sénat 28 février 2022 : Proposition de loi visant à renforcer le droit à l'avortement.<a href="http://www.senat.fr/rap/l20-263/l20-2634.html" target="_blank" rel="noreferrer noopener">
               http://www.senat.fr/rap/l20-263/l20-2634.html
               </a>.
                   </li>  
                  
              </ul>
              <br></br>
              Documentations téléchargeables :

              <ul>
                  <li>
                  <a href={RecommandationIVG} target="_blank" rel="noreferrer noopener">Recommandation, les bonnes pratiques, interruption volontaire de grossesse par méthode médicamenteuse – mise à jour. Résumé. 
</a> 
                  </li>
                  <li>
                  Protocole IVG médicamenteuse hors établissement : <a href="https://www.has-sante.fr/upload/docs/image/png/2021-03/reco406_synthese_ivg_mel_2021-03-25_09-31-31_282.png" target="_blank" rel="noreferrer noopener">https://www.has-sante.fr/upload/docs/image/png/2021-03/reco406_synthese_ivg_mel</a> 
                  </li>
                  <li>
                  <a href={Argumentaire} target="_blank" rel="noreferrer noopener">Recommandation, les bonnes pratiques, interruption volontaire de grossesse par méthode médicamenteuse – mise à jour. Argumentaire.</a>  
                  </li>

                  <li>
                      ANSM : <a href="https://ansm.sante.fr/actualites/ivg-medicamenteuses-maintien-de-la-possibilite-de-les-realiser-jusqua-la-7-semaine-de-grossesse-en-dehors-dun-etablissement-de-sante" target="_blank" rel="noreferrer noopener">lien Information</a>
                  </li>
              </ul>
              </p>           




            </div>
            <div className="BorderApp"></div>

            

        </div>
    )

}



export default Covid;
