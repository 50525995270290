import React  from "react";




import "./PremiereConsultationMajAno.css";


function PremiereConsultationMajAno(props) {

  

 

  return (
      <div className="Consultation">
          <h1>Patiente majeure anonyme</h1>
          <br></br>
          <p>
          Pour les femmes majeures, l’avortement anonyme est impossible.<br></br>
          Seul un règlement de la totalité du forfait lui permettrait un anonymat certain.<a href="https://www.legifrance.gouv.fr/" target="_blank" rel="noreferrer noopener" >(Lien textes légaux)</a>

          </p>
          
         

      </div>
    
  );






  
 
}

export default PremiereConsultationMajAno;
