import React from "react";




import "./OuiCovidDeuxiemeCon.css";


function OuiCovidDeuxiemeCon() {


 

  return (
    <div> 
        
    <p>
    Consultation possible par téléconsultation par tous professionnels de santé.     
    <br></br>
    <br></br>
    Si vous n’êtes pas le professionnel de santé prenant en charge l’IVG, il convient d’orienter la patiente vers un professionnel orthogéniste par la suite de cette consultation. 
    <br></br>
    <ul>
        <li>
          <a target="_blank" rel="noreferrer noopener" href="https://ivg.gouv.fr/">
          Lien ivg.gouv
          </a>
        
        </li>
        <li>
          <a target="_blank" rel="noreferrer noopener" href="https://www.ivglesadresses.org/">
          Lien IVGlesadresses.org
          </a>
        
        </li>
      </ul>
    
    
    </p>
    <p>
   Pour plus d’informations, se reporter à l’onglet « actualité ». 
Le décret n° 2022-212 du 19 février 2022 autorise les téléconsultations pour les interruptions par voie médicamenteuse hors établissement de santé. 
   </p>

     
    </div>
  );






  
 
}

export default OuiCovidDeuxiemeCon;