import React from "react";




import "./TxtDeuxiemeConMaj.css";


function TxtDeuxiemeConMaj() {


 

  return (
    <div> 
        
    <p>
    Cette 2ème consultation peut être effectuée par tout médecin ou sage femme, prenant en charge l’IVG ou non. Elle fait partie du tarif forfaitaire à l’IVG.</p>
   
   <p>
   Si vous n’êtes pas le médecin ou la sage femme prenant en charge l’IVG, il convient de ré-adresser la patiente au terme de la consultation à un professionnel pouvant effectuer cet acte.
   </p>
        
 
     
    </div>
  );






  
 
}

export default TxtDeuxiemeConMaj;