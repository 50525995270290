import React from "react";




import "./OuiCovid.css";


function OuiCovid() {


 

  return (
    <div> 
        
    <p>
   
    Condition : Outils informatique fiable pour les documents nécessaires à la pratique de l’IVG dans le cadre réglementaire.<br></br>
    
    
    </p>
   <p>
   Pour plus d’informations, se reporter à l’onglet « actualité ». 
Le décret n° 2022-212 du 19 février 2022 autorise les téléconsultations pour les interruptions par voie médicamenteuse hors établissement de santé. 
   </p>
    </div>
  );






  
 
}

export default OuiCovid;
