import React from "react";





import "./TxtPostIVG.css";


function TxtPostIVG() {


 

  return (
    <div> 
        
        <p>
            Cette consultation intervient 14 à 21 jours après la prise médicamenteuse. 

  

            </p>
           
 
     
    </div>
  );






  
 
}

export default TxtPostIVG;