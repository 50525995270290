import React from "react";




import "./TxtPremierePriseCovid.css";


function TxtPremierePriseCovid() {


 

  return (
    <div> 
        
    <p>
    Téléconsultation<br></br>
    Condition : Outils informatique fiable pour les documents nécessaires à la pratique de l’IVG.<br></br>
    </p>
    <p>
    Dans le cadre de l’Etat d’urgence sanitaire, la 2eme consultation préalable est supprimée. Il est nécessaire d’une deuxième téléconsultation afin d’attester de la prise médicamenteuse, après le passage à la pharmacie de la patiente.
    </p>
    <p>
    Le site est en cours de mise à jour. Veuillez vous référer à la page dédiée Covid accessible à partir de la page d’accueil.
        </p>
        
 
     
    </div>
  );






  
 
}

export default TxtPremierePriseCovid;